<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('surveys')"
                        :isNewButton="checkPermission('survey_store')"
                        @new-button-click="add"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('surveys')"
                              :isNewButton="checkPermission('survey_store')"
                              @new-button-click="add"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.name"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="datatable.queryParams.filter.status"
                            ></status-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="modal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? "new" : "edit").toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="form">
                        <div class="row mb-5 align-items-center">
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('name')">
                                    <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                        <b-input-group class="mb-1" >
                                            <b-form-input v-model="form.name"
                                                          :state="errors[0] ? false : null"/>
                                        </b-input-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('explanation')">
                                    <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                                        <b-input-group class="mb-1" >
                                            <b-form-textarea v-model="form.explanation"
                                                             :state="errors[0] ? false : null"/>
                                        </b-input-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('type')">
                                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                        <parameter-selectbox code="survey_types" v-model="form.type"
                                                             :validate-error="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('necessity')">
                                    <ValidationProvider name="necessity" rules="required" v-slot="{valid, errors}">
                                        <multi-selectbox :multiple="false" v-model="form.necessity" :options="[{value:0, text: $t('not_required')},{value:1, text: $t('required')}]"
                                                         :validate-error="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('start_date')">
                                    <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                                        <b-form-input v-model="form.start_date" type="datetime-local" :locale="'EN'"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('end_date')">
                                    <ValidationProvider name="end_date" rules="required" v-slot="{valid, errors}">
                                        <b-form-input v-model="form.end_date" type="datetime-local" locale="EN"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('rank')">
                                    <ValidationProvider name="rank" rules="required" v-slot="{valid, errors}">
                                        <b-form-input v-model="form.rank" type="number" step="1"/>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('user_type')">
                                    <ValidationProvider name="user_type" rules="required" v-slot="{valid, errors}">
                                        <multi-selectbox v-model="form.user_type"
                                                         :options="[{value:'student', text: $t('student')},]"
                                                         :validate-error="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <b-form-group :label="$t('roles')">
                                    <ValidationProvider name="roles" rules="" v-slot="{valid, errors}">
                                        <role-selectbox :multiple="true" v-model="form.roles" :validate-error="errors[0]"/>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('status')">
                                        <status-selectbox v-model="form.status"
                                                          :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="student_number" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_number')">
                                        <b-form-textarea
                                            id="textarea"
                                            v-model="form.student_numbers"
                                            rows="3"
                                            max-rows="6"
                                            v-only-number-and-enter="true"
                                        >
                                        </b-form-textarea>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <div class="col-12 mt-3 d-flex">
                                <b-button
                                    @click="store"
                                    type="button"
                                    variant="primary"
                                    class="btn-lg mr-2"
                                >
                                    {{ $t("save") | toUpperCase }}
                                </b-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import SurveyService from "@/services/SurveyService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import RoleSelectbox from "@/components/interactive-fields/RoleSelectbox";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";

export default {
    components: {
        RoleSelectbox,
        ParameterSelectbox,
        MultiSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        StatusSelectbox,
        LinedTextarea
    },
    metaInfo() {
        return {
            title: this.$t("surveys"),
        };
    },
    data() {
        return {
            id: 0,
            form: {
                user_type: [],
                student_numbers: "",
            },
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "survey_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "survey_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                            {
                                text: this.$t("questions"),
                                class: "ri-list-check",
                                permission: "survey_show",
                                callback: (row) => {
                                    this.$router.push("/surveys/" + row.id + "/questions");
                                },
                            },
                        ],
                    },
                    {
                        label: "ID",
                        field: "id",
                        sortable: true,
                    },
                    {
                        label: this.$t("name"),
                        field: "name",
                        sortable: true,
                    },
                    {
                        label: this.$t("type"),
                        field: ("type"),
                        sortable: true,
                    },
                    {
                        label: this.$t("status"),
                        field: ("status"),
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    computed: {
        studentNumbersComp() {
            const data = []
            const studentNumberArr = this.form.student_numbers?.split(/\n/) || [];
            studentNumberArr.forEach(i => data.push(i))
            return data
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return SurveyService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        loadData() {
            if (this.id > 0) {
                SurveyService.get(this.id)
                    .then((response) => {
                        this.$refs.form.reset();
                        this.form = response.data.data;
                        this.form.student_numbers = response.data.data.student_numbers.join('\n')
                        this.$refs.modal.$refs.commonModal.show();
                    })
                    .catch((error) => {
                        if (error.data.message) {
                            this.$toast.error(this.$t("api." + error.data.message));
                        }
                    });
            }
        },
        async store() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                if (this.id == 0) {
                    SurveyService.store({ ...this.form, student_numbers: this.studentNumbersComp })
                        .then((response) => {
                            this.$toast.success(
                                this.$t("api." + response.data.message)
                            );
                            this.$refs.modal.$refs.commonModal.hide();
                            this.getRows();
                            this.form.student_numbers = "";
                        })
                        .catch((e) => {
                            if (e.data.message) {
                                this.$toast.error(
                                    this.$t("api." + e.data.message)
                                );
                            }
                        });
                } else {
                    SurveyService.update(this.id, { ...this.form, student_numbers: this.studentNumbersComp })
                        .then((response) => {
                            this.$toast.success(
                                this.$t("api." + response.data.message)
                            );
                            this.$refs.modal.$refs.commonModal.hide();
                        })
                        .catch((e) => {
                            if (e.data.message) {
                                this.$toast.error(
                                    this.$t("api." + e.data.message)
                                );
                            }
                        });
                }

            }
        },
        delete(id) {
            this.deleteModal(() => {
                SurveyService.del(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t("api." + error.data.message));
                    });
            });
        },
        add() {
            this.id = 0;
            this.$refs.form.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        },

    },
};
</script>

